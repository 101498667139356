'use strict';

window.vavadaCode = {};

require('mdn-polyfills/Array.prototype.forEach');
require('mdn-polyfills/NodeList.prototype.forEach');

const { windowMobileBreakpoint } = require('./constants/breakpoints.js');

// data-key= ... using for invoking code just on a page what where we need use our code

let moduleJivoInit = require('./component/jivo_custom.js');
moduleJivoInit();

let moduleVivoChatInit = require('./component/vivo_chat_custom.js');
moduleVivoChatInit();

const moduleSnowplow = require('./module/snowplow/main');
moduleSnowplow();

if (document.querySelector('[data-pop_up]')) {
    let modulePopUp = require('./module/pop_up.js');
    modulePopUp();
}

if (document.getElementById('carousel_main')) {
    const switchSlideTimeout = 10000;
    const container = '#carousel_main';
    const containerElement = document.querySelector(container);
    const initClassName = 'carousel_init';

    const KeenSlider = require('keen-slider/keen-slider.js');
    const slider = new KeenSlider(
        container,
        {
            loop: true,
            drag: false,
            created: () => {
                containerElement.classList.remove(initClassName);
            },
        }, [
            (slider) => {
                let timeout;

                const nextTimeout = () => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        slider.next();
                    }, switchSlideTimeout);
                };

                slider.on('created', nextTimeout);
                slider.on('animationStarted', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]
    );
}

const ladderCarouselInit = require('./component/ladder_carousel_init.js');
ladderCarouselInit();

if (document.querySelector('[data-key=deposit_funds]')) {
    let moduleDepositFunds = require('./module/deposit_funds.js');
    moduleDepositFunds();
}

if (document.querySelector('[data-key=game_play]')) {
    let moduleGamePlay = require('./module/game_play.js');
    moduleGamePlay();
}

if (document.querySelector('[data-tournament=timer]')) {
    const moduleTournamentTimer = require('./module/tournament/timers.js');
    moduleTournamentTimer();
}

if (document.querySelector('[data-tournament=game_menu]') &&
    document.querySelector('[data-game=menu]')) {
    const moduleTournamentGameMenu = require('./module/tournament/game_menu.js');
    moduleTournamentGameMenu();
}

if (document.querySelector('[data-key=wallet]')) {
    let moduleWalletPages = require('./module/wallet_pages.js');
    moduleWalletPages();
}

if (document.querySelector('[data-scroll=horizontal]')) {
    let moduleScrollHorizontal = require('./component/scroll_horizontal.js');
    moduleScrollHorizontal();
}

if (document.querySelector('[data-select=radio]')) {
    let moduleMechanicSelect = require('./component/mechanic_select.js');
    moduleMechanicSelect();
}

if (document.querySelector('[data-input_file=box]')) {
    let moduleMechanicInputFile = require('./component/mechanic_input_file.js');
    moduleMechanicInputFile();
}

if (document.querySelector('[data-input_password=box]')) {
    let moduleMechanicInputPassword = require('./component/mechanic_input_password.js');
    moduleMechanicInputPassword();
}

if (document.querySelector('[data-game=catalog]')) {
    let moduleAjaxGameCatalog = require('./module/ajax_game_catalog.js');
    moduleAjaxGameCatalog();
}

if (document.querySelector('[data-gamecard]')) {
    if (document.querySelector('[data-ajax=favorite_gamecard]')) {
        require('./component/gamecard/favorite.js')(document);
    }

    if (document.querySelector('[data-gamecard=show]')) {
        require('./component/gamecard/show.js')(document);
    }
}

if (document.querySelector('[data-clipboard=btn]')) {
    let Clipboard = require('clipboard/dist/clipboard.min.js');
    new Clipboard('[data-clipboard=btn]');
}

const flashes = document.querySelectorAll('[data-flash]');
if (flashes.length) {
    let closeFlashesByTimeout = require('./component/flashes.js');
    closeFlashesByTimeout();
}

const navigationBeams = document.querySelectorAll('[data-navigation=beam]');
if (navigationBeams[0] && navigationBeams[0].querySelector('[data-navigation=active]')) {
    const NavigationDots = require('./component/navigation_dots.js')();

    navigationBeams.forEach((beam) => {
        new NavigationDots(beam);
    });
}

const inputsForSuggestion = document.querySelectorAll('[data-input-suggestion]');
if (inputsForSuggestion[0]) {
    const controller = require('./component/input_suggestion/controller');
    controller(inputsForSuggestion);
}

if (document.querySelector('[data-fingerprint]')) {
    const moduleBrowserFingerprint = require('./component/browser_fingerprint');
    moduleBrowserFingerprint();
}

const autosubmitForms = document.querySelectorAll('form[data-autosubmit]');
if (autosubmitForms[0]) {
    const moduleAutosubmitForm = require('./component/autosubmit_form');
    const params = {};

    if (document.querySelector('[data-key=wallet]')) {
        const confirmProcessing = document.getElementById('confirm_processing');
        if (confirmProcessing) {
            params.onLoad = (event) => {
                const iframe = event.target;
                let displayIframe = true;
                try {
                    const iframeDocument = iframe.contentWindow.document;
                    displayIframe = iframeDocument.getElementById('direct_exists') === null;
                } catch (error) {
                }

                if (displayIframe) {
                    confirmProcessing.classList.add('x_hide');
                    iframe.classList.remove('x_hide');
                } else {
                    confirmProcessing.classList.remove('x_hide');
                    iframe.classList.add('x_hide');
                }
            };
        }

        params.iframeModifier = 'wallet_iframe';
    }

    autosubmitForms.forEach((form) => {
        params.form = form;
        moduleAutosubmitForm(params);
    });
}

const gameFilter = document.querySelector('[data-game-filter]');
if (gameFilter) {
    const countOfOptionsInOneColumn = 9;
    const options = gameFilter.querySelectorAll('option');
    if (options.length > countOfOptionsInOneColumn) {
        gameFilter.classList.add('x_columns');
    }
}

if (document.querySelector('select')) {
    const selectsForRedirect = document.querySelectorAll('[data-select_redirect]');
    if (selectsForRedirect[0]) {
        const redirectOnChange = require('./component/select/redirect_on_change.js');
        selectsForRedirect.forEach(select => {
            redirectOnChange(select);
        });
    }

    const nativeSelector = '.select select';
    if ((window.innerWidth > windowMobileBreakpoint) &&
        document.querySelector(nativeSelector)) {
        const selectsForOptgroups = document.querySelectorAll('[data-select_optgroup]');
        if (selectsForOptgroups[0]) {
            const optgroupsFromOptions = require('./component/select/optgroups_from_options.js');
            selectsForOptgroups.forEach(select => {
                optgroupsFromOptions(select);
            });
        }

        const choicesSelect = require('./component/select/choices_select.js');
        const params = {
            shouldSort: false,
            searchEnabled: false,
        };
        choicesSelect(nativeSelector, params);
    }
}

const elementsWithTitle = document.querySelectorAll('[title]');
if (elementsWithTitle[0]) {
    const title = require('./component/title.js');
    elementsWithTitle.forEach(element => {
        title(element);
    });
}

const dotsContainers = document.querySelectorAll('[data-dots]');
if (dotsContainers[0]) {
    const { dotsPending, dotsStopPending } = require('./component/dots.js');

    dotsContainers.forEach(container => {
        const key = container.dataset.dots;
        if (key === 'tournament_rebuy') {
            const button = document.querySelector(`[data-dots-btn=${key}]`);

            button.addEventListener('mouseenter', () => {
                dotsPending(container);
            });

            button.addEventListener('mouseleave', () => {
                dotsStopPending(container);
            });
        }
    });
}

const bonusCards = document.querySelectorAll('[data-bonus-card=card]');
if (bonusCards.length) {
    const moduleBonusCard = require('./component/bonus_card.js');
    bonusCards.forEach(card => {
        moduleBonusCard(card);
    });
}

if (document.querySelector('[data-expand]')) {
    let expandBlock = require('./component/expand_block.js');
    expandBlock();
}

if (document.querySelector('[data-box-input-with-currency]')) {
    let addCurrencyToTransferInput = require('./component/input_with_currency.js');
    addCurrencyToTransferInput();
}

const jackpots = document.querySelectorAll('[data-jackpot-amount]');
if (jackpots.length) {
    const incrementJackpot = require('./component/jackpot.js');
    jackpots.forEach((jackpot) => {
        incrementJackpot(jackpot);
    });
}

const jackpotLadder = document.querySelector('[data-jackpot-ladder]');
if (jackpotLadder) {
    let jackpotLadderInit = require('./component/jackpot_ladder.js');
    jackpotLadderInit(jackpotLadder);
}

const amountInput = document.querySelector('[data-not-multiple-amount]');
if (amountInput) {
    let increment = Number(amountInput.getAttribute('data-not-multiple-amount'));

    amountInput.addEventListener('blur', (event) => {
        let amountValue = Number(amountInput.value);
        let remainder = amountValue % 100;

        if (amountValue > 0 && remainder === 0) {
            amountInput.value = amountValue + increment;
        }
    });
}
